import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'bv-components';
import { preEventPath } from 'bv-helpers/routes';
import { useBetBuilder } from 'sportsbook-hooks';
import { t } from 'bv-i18n';
import Outcome from './price_it_up_outcome';

const BetBuilderBanner = ({ eventId, marketTypeId }) => {
  const { supported, navigate } = useBetBuilder({ eventId, marketTypeId });

  if (supported) {
    return (
      <div className="priceitup-navigate-container" onClick={navigate}>
        <div className="priceitup-launch">
          <div className="priceitup-launch__title">
            <span>{t('javascript.bet_builder.launch_title')}</span>
            <span className="priceitup-launch__title-icons" />
          </div>
          <div className="priceitup-launch__button">
            <Button
              primary
            >
              {t('javascript.bet_builder.launch_button')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="priceitup-not-supported-container">
      <div className="priceitup-launch">
        <div className="priceitup-launch__title">
          <span>{t('javascript.bet_builder.banner_title_no_bb')}</span>
          <span className="priceitup-launch__title-icons" />
        </div>
      </div>
    </div>
  );
};

BetBuilderBanner.propTypes = {
  eventId: PropTypes.number.isRequired,
  marketTypeId: PropTypes.number.isRequired,
};

const PriceItUp = ({
  eventId, meetingId, sportEventPathId, description, marketTypeId, outcomeIds, betBuilder2,
}) => {
  const navigationParams = {
    eventId,
    meetingId,
    sportEventPathId,
  };

  return (
    <div className={classnames('bvs-card priceitup-market', { betBuilder2 })}>
      <div className="priceitup-header">
        <h4>{ description }</h4>
      </div>
      {!betBuilder2 && (
        <BetBuilderBanner
          eventId={eventId}
          marketTypeId={marketTypeId}
        />
      )}

      <div className="priceitup-outcomes">
        <div className="priceitup-subtitle">{t('javascript.price_it_up.item_title')}</div>
        <div className="priceitup-events">
          {outcomeIds.map((outcomeId) => (
            <Outcome
              id={outcomeId}
              key={`price-it-up-outcome-${outcomeId}`}
              eventId={eventId}
              clickSource="price-it-up-widget"
              betBuilder2={betBuilder2}
            />
          ))}
        </div>
      </div>
      <div className="priceitup-footer">
        <Button to={preEventPath(navigationParams)} primary className="priceitup-footer__more-cta">
          {t('javascript.show_full_list')}
        </Button>
      </div>
    </div>

  );
};

PriceItUp.propTypes = {
  description: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  marketTypeId: PropTypes.number.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
  outcomeIds: PropTypes.instanceOf(Object).isRequired,
  betBuilder2: PropTypes.bool.isRequired,
};

export default PriceItUp;
