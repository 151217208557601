import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import withOutcome from 'sportsbook-with-outcome';
import { useBetBuilder } from 'sportsbook-hooks';

const EditOutcomeButton = ({ marketTypeId, eventId, outcomeId }) => {
  const { supported, navigate } = useBetBuilder({ marketTypeId, eventId, outcomeId });

  if (!supported) return null;

  return (
    <div className="priceitup-event__edit bvs-link bvs-label is-info" onClick={navigate}>
      {t('edit')}
    </div>
  );
};

EditOutcomeButton.propTypes = {
  marketTypeId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  outcomeId: PropTypes.number.isRequired,
};

export const PriceItUpOutcome = ({
  selected, id, outcome, eventId, toggleBetslip, betBuilder2,
}) => {
  const priceBtnClassnames = classnames('bvs-button-sport', {
    'priceitup-price-button': !betBuilder2,
    active: selected,
  });

  const buttonClassName = betBuilder2 ? 'bb2-piu-price-button-wrapper' : 'priceitup-price-button-wrapper';

  return (
    <div className="priceitup-event bvs-card">
      <ul className="priceitup-event__list">
        {
          outcome.legDescriptions.map((legDescription) => (
            <li key={legDescription.eventId}>{legDescription}</li>
          ))
        }
      </ul>
      {!betBuilder2 && (
        <EditOutcomeButton
          eventId={eventId}
          outcomeId={id}
          marketTypeId={outcome.marketTypeId}
        />
      )}
      <div className={buttonClassName}>
        <button
          type="button"
          className={priceBtnClassnames}
          onClick={toggleBetslip}
        >
          {outcome.formattedPrice}
        </button>
      </div>
    </div>
  );
};

PriceItUpOutcome.propTypes = {
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  betBuilder2: PropTypes.bool.isRequired,
};

export default withOutcome(PriceItUpOutcome);
